<template>
    <div class="common-layout">
        <el-container>
            <el-header>
                <el-row>
                    <el-col :span="4" :offset="20" v-if="isLogin">
                        <el-popconfirm
                            width="220"
                            :hide-icon="true"
                            title="Вы хотите выйти?"
                            confirm-button-text="Выйти"
                            cancel-button-text="нет, спасибо"
                            @confirm="exit()">
                            <template #reference>
                                <div class="login-name">
                                    {{ geName }}
                                    <el-icon color="red">
                                        <font-awesome-icon icon="fa-solid fa-right-to-bracket"/>
                                    </el-icon>
                                </div>
                            </template>
                        </el-popconfirm>
                    </el-col>
                </el-row>
            </el-header>
            <el-container>
                <el-main>
                    <div v-if="!isLogin">
                        <LoginBlock @callback="loginIn"/>
                    </div>
                    <div v-else>
                        <router-view />
                    </div>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import {useCookies} from "vue3-cookies";
import LoginBlock from "@/components/LoginBlock.vue";
import store from "@/components/store/store";
export default {
    name: 'App',
    async created() {
        this.checkLogin();
        await this.getUserLogin();
    },
    computed: {
        geName(){
            return store.state.userName;
        }
    },
    setup() {
        const cookies = useCookies(['token']);
        return {
            cookies,
        }
    },
    components: {
        LoginBlock
    },
    data() {
        return {
            isLogin: false,
            userName: ''
        }
    },
    methods: {
        async getUserLogin(){
            const token = this.cookies.cookies.get('token');
            if(this.cookies.cookies.get('token')){
                await store.dispatch('getLogin', {token: token});
            }
        },
        loginIn(request) {
            this.cookies.cookies.set('token', request);
            this.checkLogin();
        },
        checkLogin() {
            const token = this.cookies.cookies.get('token');
            this.isLogin = !!(token && token.length);
        },
        exit(){
            this.cookies.cookies.remove('token');
            this.isLogin = false;
        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 10px;
}

#app .common-layout {
    padding: 0 40px;
}
#app .login-name {
    display: flex;
    color: #5f5fff;
    cursor: pointer;
    justify-content: flex-end;
    align-items: center;
}
#app .login-name i {
    margin-left: 5px;
}
</style>
